<template>
  <router-link
    class="mt-5 work-result-item"
    :to="{ name: 'detail-view', params: { identifier: result.slug } }"
  >
    <div
      class="work-result-image-wrapper"
      :class="{ 'has-analysis': result.has_published_analysis }"
    >
      <img
        class="w-full work-result-image"
        :class="[
          noImage ? 'p-5' : '',
          result.has_published_analysis ? 'has-analysis' : '',
        ]"
        :src="imageSrc"
        :alt="noImage ? 'No work image available' : result.image.alt_text"
      />
    </div>
    <div class="mt-2 work-result-title">{{ result.title }}</div>
    <div class="work-results-location-wrapper">
      <div
        class="text-gray-medium work-result-repository"
        v-if="result.repository"
      >
        {{ result.repository.name }}
      </div>
      <div
        class="text-gray-medium font-mono text-xs my-auto work-result-accessionNumber"
        v-if="result.accession_number"
      >
        {{ result.accession_number }}
      </div>
    </div>
    <div
      class="text-gray-medium work-result-classification"
      v-if="result.classification"
    >
      {{ result.classification.name }}
    </div>
    <div class="text-gray-medium work-result-location" v-if="result.location">
      {{ result.location.name }}
    </div>
    <div class="text-gray-medium work-result-date" v-if="result.creation_date">
      {{ result.creation_date.name }}
    </div>

    <button class="has-analysis-button" v-if="result.has_published_analysis">
      Analysis ({{ result.published_analyses_count }})
    </button>
  </router-link>
</template>

<script>
import PlaceholdImage from '../../../../images/no-image.svg';

export default {
  props: {
    result: Object,
  },
  computed: {
    noImage() {
      return !this.result.image.thumb_url && !this.result.image.url;
    },
    imageSrc() {
      return (
        this.result.image.thumb_url || this.result.image.url || PlaceholdImage
      );
    },
  },
};
</script>
