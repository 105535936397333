<template>
  <h2 class="h2-title-header" v-html="block.value"></h2>
</template>

<script>
export default {
  props: {
    block: Object,
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
