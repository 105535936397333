<template>
  <div
    class="cursor-pointer h-12 w-12 border-2 border-black flex justify-center color-hamburger"
    :class="borderColor"
    @click="$emit('toggle')"
    title="Navigation Menu"
    :aria-label="buttonAction"
  >
    <svg
      v-if="open"
      xmlns="http://www.w3.org/2000/svg"
      class="h-8 w-8 self-center"
      fill="none"
      viewBox="0 0 24 24"
      :stroke="openColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      class="h-8 w-8 align-middle self-center"
      fill="none"
      viewBox="0 0 24 24"
      :stroke="closedColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    open: Boolean,
    closedColor: {
      default: 'currentColor',
      type: String,
    },
    openColor: {
      default: 'currentColor',
      type: String,
    },
  },
  computed: {
    borderColor() {
      let border = '';
      if (this.open) {
        border = `border-${this.openColor}`;
      } else {
        border = `border-${this.closedColor}`;
      }
      if (border == 'currentColor') border = 'border-black';
      return border;
    },
    buttonAction() {
      return this.open ? 'Close Navigation Menu' : 'Open Navigation Menu';
    },
  },
};
</script>
