<template>
  <router-link
    class="flex flex-col justify-between text-center w-44 h-44 element-block"
    :to="{
      name: 'search-view',
      query: { 'works[refinementList][elements][0]': element.symbol },
    }"
  >
    <span></span>
    <span class="flex flex-col">
      <span class="font-mono element-atomicNumber">{{
        element.atomicNumber
      }}</span>
      <span class="text-4xl mt-2 element-symbol">{{ element.symbol }}</span>
      <span class="element-name">{{ element.name }}</span>
    </span>
    <span class="text-xs pb-2 element-workCount element__works--count"
      >{{ element.workCount }} work{{
        element.workCount === 1 ? '' : 's'
      }}</span
    >
  </router-link>
</template>

<script>
export default {
  props: {
    element: Object,
  },
};
</script>
