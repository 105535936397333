<template>
  <div class="container dynamic-text" v-html="block.value"></div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
