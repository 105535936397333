<template>
  <div class="container mx-auto">
    <h1 class="h1-title">Methodologies Glossary</h1>
    <div class="container flex flex-wrap method-container">
      <div
        class="mb-2"
        v-for="methodology in allMethodologies"
        :key="methodology.uuid"
      >
        <MethodologyBox :methodology="methodology" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import MethodologyBox from '../components/MethodologyBox.vue';
import { inject } from 'vue';

export default {
  components: { MethodologyBox },
  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('methodologies');
  },
  data() {
    return {
      allMethodologies: [],
    };
  },
  apollo: {
    allMethodologies: {
      query: gql`
        query Methodologies {
          allMethodologies {
            edges {
              node {
                id
                name
                description
                acronym
                zoteroPublications {
                  citation
                }
              }
            }
          }
        }
      `,
      update: (data) => data.allMethodologies.edges.map((e) => e.node),
    },
  },
};
</script>
