<template class>
  <div class="container profile-container">
    <div class="news-thumbnail profile-thumbnail">
      <img :src="block.value.pic.url" :alt="block.value.pic.title" />
    </div>

    <div class="news-text">
      <div class="top h3-title" v-html="block.value.name"></div>
      <div
        class="middle profile-title h4-title"
        v-html="block.value.title"
      ></div>
      <div class="profile-text" v-html="block.value.bio"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>
