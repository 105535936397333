<template>
  <div class="container mx-auto">
    <h1 class="h1-title">Browse by Elements</h1>
    <div class="flex flex-wrap mt-10 main-thumb-container" id="elements">
      <div
        class="element mb-14 mx-7"
        :class="element.symbol"
        v-for="element in allElements"
        :key="element.uuid"
      >
        <ElementBox :element="element" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { inject } from 'vue';

import ElementBox from '../components/ElementBox.vue';

export default {
  setup() {
    const updateCiteType = inject('updateCiteType');
    updateCiteType('elements');
  },
  components: { ElementBox },
  data() {
    return {
      allElements: [],
    };
  },
  apollo: {
    allElements: {
      query: gql`
        query Elements {
          allElements {
            edges {
              node {
                uuid
                name
                description
                atomicNumber
                symbol
                workCount
              }
            }
          }
        }
      `,
      update: (data) => data.allElements.edges.map((e) => e.node),
    },
  },
};
</script>
